// src/components/Footer.js
import React from 'react';
import { FaInstagram, FaYoutube, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-gray-300 py-10">
            <div className="container mx-auto px-4 text-center">
                <h3 className="text-2xl font-bold mb-4">Connect With Us</h3>
                <p className="mb-8">Follow us on social media and stay updated on our latest courses and news.</p>
                
                <div className="flex justify-center space-x-6 mb-6">
                    {/* Instagram */}
                    <a href="https://www.instagram.com/iqraal_quran" target="_blank" rel="noopener noreferrer" aria-label="Instagram" className="text-gray-300 hover:text-green-500 transition-all duration-300">
                        <FaInstagram size={30} />
                    </a>
                    {/* YouTube */}
                    <a href="https://www.youtube.com/@myquransheikh" target="_blank" rel="noopener noreferrer" aria-label="YouTube" className="text-gray-300 hover:text-red-500 transition-all duration-300">
                        <FaYoutube size={30} />
                    </a>
                    {/* WhatsApp */}
                    <a href="https://wa.me/923295456750" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp" className="text-gray-300 hover:text-green-600 transition-all duration-300">
                        <FaWhatsapp size={30} />
                    </a>
                </div>

                <p className="text-sm">
                    &copy; {new Date().getFullYear()} MyQuranSheikh. All rights reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
