// src/components/CoursesSection.js
import React from 'react';



const CoursesSection = () => {
  return (
    <section id="courses" class="py-24 bg-gray-100">
    <div class="container mx-auto px-4">
        <h2 class="text-4xl font-bold text-center mb-16 gradient-text">Our Courses</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
           
            <div class="bg-white rounded-2xl shadow-xl overflow-hidden card-hover">
                <div class="p-8">
                    <div class="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-6">
                        <i class="fas fa-book-open text-2xl text-green-600"></i>
                    </div>
                    <h3 class="text-2xl font-bold mb-6">Noorani Qaida & Tajweed</h3>
                    <ul class="mb-8 space-y-4">
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Learn correct pronunciation of Arabic letters</li>
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Master basic vowels, symbols, and diacritics</li>
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Practice letter joining and word formation</li>
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Introduction to essential Tajweed rules</li>
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Build confidence in reading Arabic text</li>    
                    </ul>
                    <p class="text-3xl font-bold text-green-600 mb-8">$50<span class="text-lg text-gray-500">/month</span></p>
                    <button onClick={() => window.open('https://forms.gle/UnohcexPMdxnkKgi8', '_blank')} class="w-full bg-gradient-to-r from-green-600 to-green-500 text-white py-4 rounded-xl hover:from-green-700 hover:to-green-600 transition-all duration-300">
                        Enroll Now
                    </button>
                </div>       
            </div>

            <div class="bg-white rounded-2xl shadow-xl overflow-hidden card-hover">
                <div class="p-8">
                    <div class="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-6">
                        <i class="fas fa-book-open text-2xl text-green-600"></i>
                    </div>
                    <h3 class="text-2xl font-bold mb-6">Nazra & Tajweed</h3>
                    <ul class="mb-8 space-y-4">
                    <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Recite the entire Quran with proper Tajweed</li>
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Apply Tajweed rules like Ikhfa, Idgham, and Qalqalah</li>
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Focus on fluency and rhythm in recitation</li>
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Receive guidance for clear and accurate reading</li> 
                    </ul>
                    <p class="text-3xl font-bold text-green-600 mb-8">$50<span class="text-lg text-gray-500">/month</span></p>
                    <button onClick={() => window.open('https://forms.gle/UnohcexPMdxnkKgi8', '_blank')} class="w-full bg-gradient-to-r from-green-600 to-green-500 text-white py-4 rounded-xl hover:from-green-700 hover:to-green-600 transition-all duration-300">
                        Enroll Now
                    </button>
                </div>       
            </div>

            <div class="bg-white rounded-2xl shadow-xl overflow-hidden card-hover">
                <div class="p-8">
                    <div class="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-6">
                        <i class="fas fa-book-open text-2xl text-green-600"></i>
                    </div>
                    <h3 class="text-2xl font-bold mb-6">Memorization (Hifz)</h3>
                    <ul class="mb-8 space-y-4">
                    <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Memorize selected or full Quran sections</li>
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Review and reinforce memorized verses</li>
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Practice correct pronunciation with Tajweed</li>
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Develop long-term memorization techniques</li>
                        <li class="flex items-center"><i class="fas fa-check-circle text-green-600 mr-3"></i>Regular assessments to ensure accuracy and retention</li> 
                    </ul>
                    <p class="text-3xl font-bold text-green-600 mb-8">$80<span class="text-lg text-gray-500">/month</span></p>
                    <button onClick={() => window.open('https://forms.gle/UnohcexPMdxnkKgi8', '_blank')} class="w-full bg-gradient-to-r from-green-600 to-green-500 text-white py-4 rounded-xl hover:from-green-700 hover:to-green-600 transition-all duration-300">
                        Enroll Now
                    </button>
                </div>       
            </div>
          
            
        </div>
    </div>
</section>
  );
};

export default CoursesSection;
